<template>
  <v-dialog v-model="notesDialogue" max-width="1000" scrollable>
    <v-card>
      <v-card-title>Notes</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="row pt-4">
          <v-col cols="12" md="12" sm="12">
            <table class="table text-center table-bordered">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                <th class="text-center" style="width: 25%">Timestamp</th>
                <th class="text-center" style="width: 20%">Note by</th>
                <th class="text-center" style="width: 40%">Note</th>
                <th class="text-center" v-if="isCustomer">Type</th>
                <th class="text-center" v-if="isCustomer">Name</th>
                <th class="text-center" style="width: 10%">Attachment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(note,index) in notes" :key="index">
                <td class="text-center" style="width: 25%">
                  {{ note.created_on | timeStamp }}
                </td>
                <td style="width: 20%">
                  {{ note.created_by_user.first_name }} {{ note.created_by_user.last_name }}
                </td>
                <td style="width: 40%">
                  {{ note.note }}
                </td>
                <td v-if="isCustomer">
                  {{ note.note_type }}
                </td>
                <td v-if="isCustomer">
                  {{ note.trainer_name ? note.trainer_name : note.academy_name }}
                </td>
                <td style="width: 10%">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                          color="cyan"
                          v-if="note.document && note.document.id"
                          @click="openFile(note.document.file_path)"
                          v-on="on"
                      >
                        mdi-download-box
                      </v-icon>
                    </template>
                    Click to download
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color=""
            class="ma-2"
            text
            @click="closeDialogue"
        >Close
        </v-btn>
        <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="addNote"
        >Add Note
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="addNotesDialogue" max-width="600" scrollable>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text class="border-bottom mb-3">
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text="Add Note" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeAddNote">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-row class="mt-8" dense>
                  <v-col md="12">
                    <label for="">Add Note</label>
                    <v-textarea
                        name="note"
                        v-model="note"
                        outlined
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        hide-details="auto"
                        dense
                        :rules="[(v) => !!v || 'Note is required']"
                    ></v-textarea>
                  </v-col>
                  <v-col class="mt-8" dense>
                    <v-file-input
                        v-model="notesFile"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        prepend-icon
                        prepend-inner-icon="mdi-paperclip"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2"
                text
                @click="closeAddNote"
            >Close
            </v-btn
            >
            <v-btn class="ma-2 white--text teal-color" text @click="saveNote"
            >Add
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-dialog>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon},

  props: {
    workshopBookingId: {type: Number, default: null},
    trainerBookingId: {type: Number, default: null},
    customerId: {type: Number, default: null},
    notesDialogue: {type: Boolean, default: false},
    notes: {type: Array, default: () => []},
    isCustomer:{type:Boolean,default:false}
  },

  data() {
    return {
      addNotesDialogue: false,
      note: null,
      notesFile: null,
      valid: false,
    }
  },
  methods: {
    closeDialogue() {
      this.$emit('closeDialogue');
    },
    refreshData() {
      this.$emit('refreshData');
    },
    addNote() {
      this.addNotesDialogue = true;
    },
    closeAddNote() {
      this.addNotesDialogue = false;
      this.note = null;
      this.notesFile = null;
    },
    saveNote() {

      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }


      let formData = new FormData();

      formData.append('note', this.note)

      formData.append('customer_id', this.customerId)

      if (this.notesFile) {
        formData.append('notes_file', this.notesFile)
      }
      let url = `venues/customers/notes/save`;

      if (this.workshopBookingId) {
        url = `venues/workshops/schedules/booking/save-notes`;
        formData.append('booking_id', this.workshopBookingId)
      }

      else if (this.trainerBookingId) {
        url = `venues/trainers/customers/save-notes`;
        formData.append('booking_id', this.trainerBookingId)
      }


      this.showLoader("Loading...");
      this.$http.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.refreshData();
              this.closeAddNote();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    }
  }
}

</script>

<style scoped>

</style>